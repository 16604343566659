import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  players: [],
  playerSelected: 0,
  endGame: false,
  pontos: 100,
  descontarPontos: true,
  podeVoltar: false,
  placarPlayerAnterior: 0
};

export default function(state = INITIAL_STATE, action) {
  let players = [];
  let playerSelected = 0;
  let placarPlayerAnterior = 0;
  switch (action.type) {
    case actionsTypes.SET_PLAYERS_PLACAR:
      return { ...state, players: action.players };

    case actionsTypes.SET_PLAYER_SELECTED_PLACAR:
      return { ...state, playerSelected: action.playerSelected };

    case actionsTypes.NEXT_PLAYER_PLACAR:
      players = state.players;
      placarPlayerAnterior = players[state.playerSelected].score;

      if (action.acertou) {
        players[state.playerSelected] = {
          ...players[state.playerSelected],
          score:
            players[state.playerSelected].score +
            (isNaN(state.pontos) ? 0 : state.pontos)
        };
      } else {
        if (state.descontarPontos) {
          players[state.playerSelected] = {
            ...players[state.playerSelected],
            score:
              players[state.playerSelected].score -
                (isNaN(state.pontos) ? 0 : state.pontos) <=
              0
                ? 0
                : players[state.playerSelected].score -
                  (isNaN(state.pontos) ? 0 : state.pontos)
          };
        }
      }

      playerSelected = 0;

      if (state.playerSelected < state.players.length - 1)
        playerSelected = state.playerSelected + 1;

      return {
        ...state,
        players,
        playerSelected,
        placarPlayerAnterior,
        podeVoltar: true
      };

    case actionsTypes.PREVIOUS_PLAYER_PLACAR:
      if (!state.podeVoltar) return state;

      players = state.players;

      if (players[state.playerSelected - 1]) {
        players[state.playerSelected - 1].score = state.placarPlayerAnterior;

        return {
          ...state,
          players,
          playerSelected: state.playerSelected - 1,
          podeVoltar: false,
          placarPlayerAnterior: 0
        };
      } else {
        players[players.length - 1].score = state.placarPlayerAnterior;

        return {
          ...state,
          players,
          playerSelected: players.length - 1,
          podeVoltar: false,
          placarPlayerAnterior: 0
        };
      }

    case actionsTypes.SET_END_GAME_PLACAR:
      return {
        ...state,
        endGame: action.endGame,
        pontos: INITIAL_STATE.pontos
      };

    case actionsTypes.SET_PLAYER_SCORE:
      return { ...state, pontos: action.pontos };

    case actionsTypes.RESET_PLAYER_SCORE:
      return { ...state, pontos: INITIAL_STATE.pontos };

    case actionsTypes.SET_DESCONTAR_PONTOS:
      return { ...state, descontarPontos: action.descontarPontos };

    case actionsTypes.SET_STATE:
      if (action.state?.PlacarReducer) return action.state.PlacarReducer;
      return state;

    default:
      return state;
  }
}

import { takeLatest, select } from 'redux-saga/effects';
import actionsTypes from './constants/ActionsTypes';

function* mySaga() {
  yield takeLatest('*', onAllAction);
}

function* onAllAction(action) {
  if (action.fromStudent) return;
  if (action.fromTeacher) return;

  const state = yield select();

  if (
    action.type === actionsTypes.SET_SLIDE ||
    action.type === actionsTypes.ADD_PARTICIPANT ||
    action.type === actionsTypes.SET_SIZE_CONTENT ||
    action.type === actionsTypes.SET_IS_MUTE_ALL ||
    action.type === actionsTypes.SET_IS_PREVIOUS_AUDIO_STATUS ||
    action.type === actionsTypes.SET_CONNECTED_USERS
  ) return;

  if (!state.AulaReducer.isLive) return;
  if (!state.AulaReducer.room) return;

  const slideId = state.SlideReducer.slide.id;

  action['fromTeacher'] = true;

  let isSaveAction = true;
  if (
    (action.type === 'SET_PLAY_BLOCK_KEY' && !action.isPlay) ||
    action.type === 'SET_STATE_PLAY_AUDIOS'
  ) {
    isSaveAction = false;
  }

  const socket = state.SocketReducer.socket;
  socket.emit('run-action', slideId, action, state.AulaReducer.room, isSaveAction);
  //socket.disconnect();
}

export default mySaga;

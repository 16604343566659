import { combineReducers } from 'redux';

import SlideReducer from './SlideReducer';
import TextReducer from './TextReducer';
import ShowReducer from './ShowReducer';
import BreadcrumbReducer from './BreadcrumbReducer';
import QuizReducer from './QuizReducer';
import AulaReducer from './AulaReducer';
import ConfigReducer from './ConfigReducer';
import PracticeReducer from './PracticeReducer';
import PlacarReducer from './PlacarReducer';
import TotemReducer from './TotemReducer';
import CarrinhoReducer from './CarrinhoReducer';
import PagamentoReducer from './PagamentoReducer';
import ContentReducer from './ContentReducer';
import ExecucaoReducer from './ExecucaoReducer';
import CardReducer from './CardReducer';
import DragnDropReducer from './DragnDropReducer';
import MatchReducer from './MatchReducer';
import TicTacToeReducer from './TicTacToeReducer';
import FoodGameReducer from './FoodGameReducer';
import SnookerGameReducer from './SnookerGameReducer';
import GlobeGameReducer from './GlobeGameReducer';
import MemoryGameReducer from './MemoryGameReducer';
import ParkingGameReducer from './ParkingGameReducer';
import SheepGameReducer from './SheepGameReducer';
import PrepositionGameReducer from './PrepositionGameReducer';
import SequenceImagesGameReducer from './SequenceImagesGameReducer';
import WorkplaceGameReducer from './WorkplaceGameReducer';
import CardChallengeReducer from './CardChallengeReducer';
import SelectorReducer from './SelectorReducer';
import SocketReducer from './SocketReducer';
import ClickableReducer from './ClickableReducer';
import AbaReducer from './AbaReducer';

export default combineReducers({
  SlideReducer,
  TextReducer,
  ShowReducer,
  BreadcrumbReducer,
  QuizReducer,
  AulaReducer,
  ConfigReducer,
  PracticeReducer,
  PlacarReducer,
  TotemReducer,
  CarrinhoReducer,
  PagamentoReducer,
  ContentReducer,
  ExecucaoReducer,
  CardReducer,
  DragnDropReducer,
  MatchReducer,
  TicTacToeReducer,
  FoodGameReducer,
  SnookerGameReducer,
  GlobeGameReducer,
  MemoryGameReducer,
  ParkingGameReducer,
  SheepGameReducer,
  PrepositionGameReducer,
  SequenceImagesGameReducer,
  WorkplaceGameReducer,
  CardChallengeReducer,
  SelectorReducer,
  SocketReducer,
  ClickableReducer,
  AbaReducer
});

import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  totems: {},
  change: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.ADD_TOTEM:
      return {
        ...state,
        totems: {
          ...state.totems,
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };

    case actionsTypes.SET_TOTEM:
      return {
        ...state,
        totems: {
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };
    case actionsTypes.SET_STATE:
      if (action.state?.TotemReducer) return action.state.TotemReducer;
      return state;
    default:
      return state;
  }
}

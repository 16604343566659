import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
    socket: null,
    connectedUsers: 0,
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionsTypes.SET_SOCKET:
            return {
                ...state,
                socket: action.socket
            };
        case actionsTypes.SET_CONNECTED_USERS:
            return {
                ...state,
                connectedUsers: action.connectedUsers
            };

        default:
            return state;
    }
}

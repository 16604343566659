import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  cursoId: null,
  subdivisaoId: null,
  aulaId: null,
  livroId: null,
  livroNome: '',
  corPrimaria: '',
  corTextoPrimaria: '',
  nivelId: null,
  nivelNome: '',
  subnivelId: null,
  subnivelNome: '',
  path: '',
  aula: {},
  isLive: false,
  room: null,
  participants: 0,
  isSetPreviousAudioStatus: false,
  isMuteAll: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_AULA_ID:
      return { ...state, aulaId: action.aulaId };

    case actionsTypes.SET_LIVRO_ID:
      return { ...state, livroId: action.livroId };

    case actionsTypes.SET_LIVRO_NOME:
      return { ...state, livroNome: action.livroNome };

    case actionsTypes.SET_COR_PRIMARIA:
      return { ...state, corPrimaria: action.corPrimaria };

    case actionsTypes.SET_COR_TEXTO_PRIMARIA:
      return { ...state, corTextoPrimaria: action.corTextoPrimaria };

    case actionsTypes.SET_CURSO_ID:
      return { ...state, cursoId: action.cursoId };

    case actionsTypes.SET_SUBDIVISAO_ID:
      return { ...state, subdivisaoId: action.subdivisaoId };

    case actionsTypes.SET_AULA_PATH:
      return { ...state, path: action.path };

    case actionsTypes.SET_AULA:
      return { ...state, aula: action.aula };

    case actionsTypes.SET_STATE:
      if (action.state?.AulaReducer) return action.state.AulaReducer;
      return state;

    case actionsTypes.SET_IS_LIVE:
      return { ...state, isLive: action.isLive };

    case actionsTypes.SET_ROOM:
      return { ...state, room: action.room };

    case actionsTypes.ADD_PARTICIPANT:
      return { ...state, participants: state.participants + 1};

    case actionsTypes.SET_IS_MUTE_ALL:
      return { ...state, isMuteAll: action.isMuteAll };

    case actionsTypes.SET_IS_PREVIOUS_AUDIO_STATUS:
      return { ...state, isSetPreviousAudioStatus: action.isSetPreviousAudioStatus };

    default:
      return state;
  }
}

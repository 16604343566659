import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  slide: {},
  toast: { show: false, message: '' },
  slidesCount: 0,
  inInput: false
};

export default function(state = INITIAL_STATE, action) {
  let slide = {};

  switch (action.type) {
    case actionsTypes.SET_SLIDE:
      return { ...state, slide: action.slide };

    case actionsTypes.SET_SLIDES_COUNT:
      return { ...state, slidesCount: action.slidesCount };

    case actionsTypes.SET_SLIDE_ID:
      slide = {
        ...state.slide,
        id: action.id
      };
      return {
        ...state,
        slide
      };

    case actionsTypes.SET_AULA_ID:
      return { ...state, aulaId: action.aulaId };

    case actionsTypes.SET_TOAST:
      return { ...state, toast: action.toast };

    case actionsTypes.SET_BLOCK:
      let conteudo = { ...state.slide.conteudo };

      let blocks = {
        ...conteudo.blocks,
        [action.blockKey]: action.block
      };
      conteudo = {
        ...conteudo,
        blocks
      };
      slide = {
        ...state.slide,
        conteudo
      };
      return { ...state, slide };

    case actionsTypes.SET_IN_INPUT:
      return {
        ...state,
        inInput: action.inInput
      };

    case actionsTypes.SET_STATE:
      if (action.state?.SlideReducer) return action.state.SlideReducer;
      return state;

    default:
      return state;
  }
}

import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

const AppLayout = lazy(() => import('./../layouts/App'));
const AuthLayout = lazy(() => import('./../layouts/Auth'));
const AulaLayout = lazy(() => import('./../layouts/Aula'));
const LiveAulaLayout = lazy(() => import('./../layouts/LiveAula'));

const Login = lazy(() => import('../pages/Auth/Login'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const RankingAlunos = lazy(() => import('../pages/Dashboard/RankingAlunos'));
const Cursos = lazy(() => import('../pages/Cursos'));
const Divisao = lazy(() => import('../pages/Cursos/Divisao'));
const Livro = lazy(() => import('../pages/Cursos/Livros'));
const Nivel = lazy(() => import('../pages/Cursos/Nivel'));
const Aula = lazy(() => import('../pages/Cursos/Aula'));
const ClassRoom = lazy(() => import('../pages/ClassRoom'));
const PerfilAluno = lazy(() => import('../pages/PerfilAluno'));
const InfoAula = lazy(() => import('../pages/PerfilAluno/InfoAula'));

const offline = process.env.REACT_APP_AMBIENTE === 'OFFLINE';

const AppRoute = ({
  component: Component,
  layout: Layout,
  title,
  divisao = false,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Layout title={title}>
        <Component {...props} divisao={divisao}></Component>
      </Layout>
    )}
  ></Route>
);

function Router() {
  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <BrowserRouter>
        <AppRoute path="/login" exact layout={AuthLayout} component={Login} />
        <AppRoute
          path="/"
          exact
          layout={AppLayout}
          component={offline ? Cursos : Dashboard}
        />
        <AppRoute
          path="/ranking-alunos"
          exact
          layout={AppLayout}
          component={RankingAlunos}
        />

        <Route path="/perfil-aluno/:idAluno" exact component={PerfilAluno} />

        <Route
          path="/perfil-aluno/:idAluno/:idAula"
          exact
          component={InfoAula}
        />

        <AppRoute path="/cursos" exact layout={AppLayout} component={Cursos} />

        <AppRoute
          path="/:cursoId/divisao"
          exact
          layout={AppLayout}
          component={Divisao}
        />

        <AppRoute
          path="/:subdivisaoId/livro"
          exact
          layout={AppLayout}
          component={Livro}
        />

        <AppRoute
          path="/divisao/:subdivisaoId/livro"
          exact
          layout={AppLayout}
          component={Livro}
          divisao={true}
        />

        <AppRoute
          path="/:mapaId/nivel"
          exact
          layout={AppLayout}
          component={Nivel}
        />

        <AppRoute
          path="/live/aula/:slideId"
          exact
          layout={LiveAulaLayout}
          component={Aula}
        />

        <AppRoute
          path="/aula/:slideId"
          exact
          layout={AulaLayout}
          component={Aula}
        />

        <AppRoute
          exact
          path="/class-room"
          layout={AppLayout}
          component={ClassRoom}
          title="Minha sala de aula"
        />
      </BrowserRouter>
    </Suspense>
  );
}

export default Router;

import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  playBlockKey: '',
  blocksAutoExecute: [],
  indexAutoExecute: 0,
  statePlayAudios: 'stopped',
  indiceAudio: 0,
  playerSeconds: {},
  lastAudioPlayed: null,
  showBlocksKey: [],
  playingSeconds: [],
  isAudioPlaying: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_PLAY_BLOCK_KEY:
      return {
        ...state,
        playBlockKey: action.playBlockKey
      };

    case actionsTypes.SET_BLOCKS_AUTO_EXECUTE:
      return {
        ...state,
        blocksAutoExecute: action.blocksAutoExecute,
        indexAutoExecute: 0
      };

    case actionsTypes.NEXT_EXECUTE:
      if (state.indexAutoExecute + 1 < state.blocksAutoExecute.length)
        return {
          ...state,
          playBlockKey: state.blocksAutoExecute[state.indexAutoExecute + 1],
          indexAutoExecute: state.indexAutoExecute + 1
        };

      return INITIAL_STATE;

    case actionsTypes.SET_STATE_PLAY_AUDIOS:
      return {
        ...state,
        statePlayAudios: action.state
      };

    case actionsTypes.SET_INDICE_AUDIO:
      return {
        ...state,
        indiceAudio: action.indice
      };

    case actionsTypes.SET_PLAYER_SECONDS:
      return {
        ...state,
        playerSeconds: {
          ...state.playerSeconds,
          [action.blockKey]: action.playerSeconds
        }
      };

    case actionsTypes.SET_PLAYING_SECONDS:
      return {
        ...state,
        playingSeconds: {
          ...state.playingSeconds,
          [action.blockKey]: action.playingSeconds
        }
      };

    case actionsTypes.SET_LAST_AUDIO_PLAYED:
      return {
        ...state,
        lastAudioPlayed: action.lastAudioPlayed
      };

    case actionsTypes.RESET_EXECUCAO_STATE:
      return INITIAL_STATE;

    case actionsTypes.SET_STATE:
      if (action.state?.ExecucaoReducer) return action.state.ExecucaoReducer;
      return state;

    case actionsTypes.ADD_SHOW_BLOCKS_INDEX:
      if (state.showBlocksKey.includes(action.blockKey)) {
        const indexToRemove = state.showBlocksKey.indexOf(action.blockKey);
        return {
          ...state,
          showBlocksKey: [
            ...state.showBlocksKey.slice(0, indexToRemove),
            ...state.showBlocksKey.slice(indexToRemove + 1)
          ]
        };
      }
      return {
        ...state,
        showBlocksKey: [...state.showBlocksKey, action.blockKey]
      };

    case actionsTypes.SET_IS_AUDIO_PLAYING:
      return { ...state, isAudioPlaying: action.isAudioPlaying };

    default:
      return state;
  }
}

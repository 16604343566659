const actionsTypes = {
  TOGGLE_SHOW_MODAL_BACKGROUND: 'TOGGLE_SHOW_MODAL_BACKGROUND',
  TOGGLE_SHOW_MODAL_IMAGE: 'TOGGLE_SHOW_MODAL_IMAGE',
  TOGGLE_SHOW_MODAL_CRONOMETRO: 'TOGGLE_SHOW_MODAL_CRONOMETRO',
  TOGGLE_SHOW_MODAL_TABLE: 'TOGGLE_SHOW_MODAL_TABLE',
  SET_CONTENT: 'SET_CONTENT',
  SET_FORMAT: 'SET_FORMAT',
  SET_HTML: 'SET_HTML',
  SET_COLUMN_CONTENT: 'SET_COLUMN_CONTENT',
  SET_COLUMN_HTML: 'SET_COLUMN_HTML',
  SET_COLUMN_FORMAT: 'SET_COLUMN_FORMAT',
  SET_BLOCK_POSITION: 'SET_BLOCK_POSITION',
  SET_BLOCK_SIZE: 'SET_BLOCK_SIZE',
  SET_BLOCK_INDEX: 'SET_BLOCK_INDEX',
  SET_SELECTED_ALIGN: 'SET_SELECTED_ALIGN',
  SET_SELECTED_BOLD: 'SET_SELECTED_BOLD',
  SET_SELECTED_UNDERLINE: 'SET_SELECTED_UNDERLINE',
  SET_SELECTED_ITALIC: 'SET_SELECTED_ITALIC',
  SET_SELECTED_COLOR: 'SET_SELECTED_COLOR',
  SET_SELECTED_FONT_SIZE: 'SET_SELECTED_FONT_SIZE',
  TOGGLE_SHOW_MODAL_VIDEO: 'TOGGLE_SHOW_MODAL_VIDEO',
  TOGGLE_SHOW_MODAL_AUDIO: 'TOGGLE_SHOW_MODAL_AUDIO',
  ADD_BLOCK: 'ADD_BLOCK',
  DELETE_SELECTED_BLOCK: 'DELETE_SELECTED_BLOCK',
  SET_SLIDE_INDEX: 'SET_SLIDE_INDEX',
  ADD_SLIDE: 'ADD_SLIDE',
  TOGGLE_FULL_SCREEN: 'TOGGLE_FULL_SCREEN',
  SET_SELECTED_CELL: 'SET_SELECTED_CELL',
  SET_TABLE: 'SET_TABLE',
  SET_AUDIO_ADD_MODE: 'SET_AUDIO_ADD_MODE',
  SET_QUIZ_ANSWER_HTML: 'SET_QUIZ_ANSWER_HTML',
  SET_QUIZ_ANSWER_CONTENT: 'SET_QUIZ_ANSWER_CONTENT',
  SET_QUIZ_ANSWER_FORMAT: 'SET_QUIZ_ANSWER_FORMAT',
  ADD_ANSWER_QUIZ: 'ADD_ANSWER_QUIZ',
  SET_SLIDE: 'SET_SLIDE',
  TOGGLE_SHOW_MODAL_QUIZ: 'TOGGLE_SHOW_MODAL_QUIZ',
  TOGGLE_SHOW_LAYOUT_SELECTOR: 'TOGGLE_SHOW_LAYOUT_SELECTOR',
  TOGGLE_SHOW_GAMETOOLS_SELECTOR: 'TOGGLE_SHOW_GAMETOOLS_SELECTOR',
  SET_SHOW_TEXT_TOOLS: 'SET_SHOW_TEXT_TOOLS',
  REMOVE_SLIDE: 'REMOVE_SLIDE',
  TOGGLE_SHOW_MODAL_CONTROLS: 'TOGGLE_SHOW_MODAL_CONTROLS',
  SET_SLIDE_ID: 'SET_SLIDE_ID',
  SET_AULA_ID: 'SET_AULA_ID',
  SET_LIVRO_ID: 'SET_LIVRO_ID',
  SET_COR_PRIMARIA: 'SET_COR_PRIMARIA',
  SET_LIVRO_NOME: 'SET_LIVRO_NOME',
  SET_CURSO_ID: 'SET_CURSO_ID',
  SET_SUBDIVISAO_ID: 'SET_SUBDIVISAO_ID',
  SET_TOAST: 'SET_TOAST',
  TOGGLE_SHOW_MODAL_ADD_CURSO: 'TOGGLE_SHOW_MODAL_ADD_CURSO',
  TOGGLE_SHOW_MODAL_ADD_DIVISAO: 'TOGGLE_SHOW_MODAL_ADD_DIVISAO',
  TOGGLE_SHOW_MODAL_ADD_LIVRO: 'TOGGLE_SHOW_MODAL_ADD_LIVRO',
  TOGGLE_SHOW_MODAL_ADD_ITEM: 'TOGGLE_SHOW_MODAL_ADD_ITEM',
  TOGGLE_SHOW_JANELA_DIREITA: 'TOGGLE_SHOW_JANELA_DIREITA',
  SET_BLOCK_IMAGE: 'SET_BLOCK_IMAGE',
  TOGGLE_SHOW_MODAL_CLICKABLE: 'TOGGLE_SHOW_MODAL_CLICKABLE',
  SET_HIDE_BLOCK_POSITION: 'SET_HIDE_BLOCK_POSITION',
  SET_HIDE_BLOCK_SIZE: 'SET_HIDE_BLOCK_SIZE',
  SET_QUIZ_ENUNCIADO: 'SET_QUIZ_ENUNCIADO',
  SET_QUIZ_ADD_QUESTAO: 'SET_QUIZ_ADD_QUESTAO',
  SET_QUIZ_TIPO_QUESTAO: 'SET_QUIZ_TIPO_QUESTAO',
  SET_QUIZ_IMAGEM: 'SET_QUIZ_IMAGEM',
  SET_QUIZ_VIDEO: 'SET_QUIZ_VIDEO',
  SET_QUIZ_QUESTAO_PERGUNTA: 'SET_QUIZ_QUESTAO_PERGUNTA',
  SET_QUIZ_QUESTAO_ADD_OPCAO: 'SET_QUIZ_QUESTAO_ADD_OPCAO',
  SET_QUIZ_QUESTAO_OPCOES_TEXTO: 'SET_QUIZ_QUESTAO_OPCOES_TEXTO',
  SET_QUIZ_QUESTAO_OPCOES_CORRETO: 'SET_QUIZ_QUESTAO_OPCOES_CORRETO',
  SET_AULA_PATH: 'SET_AULA_PATH',
  SET_CONTROLS_SLIDE: 'SET_CONTROLS_SLIDE',
  SET_SELECTED_HIDABLE: 'SET_SELECTED_HIDABLE',
  TOGGLE_SHOW_IMAGE_CONFIG: 'TOGGLE_SHOW_IMAGE_CONFIG',
  TOGGLE_SHOW_AUDIO_CONFIG: 'TOGGLE_SHOW_AUDIO_CONFIG',
  TOGGLE_SHOW_VIDEO_CONFIG: 'TOGGLE_SHOW_VIDEO_CONFIG',
  TOGGLE_SHOW_CLICKABLE_CONFIG: 'TOGGLE_SHOW_CLICKABLE_CONFIG',
  TOGGLE_SHOW_SLIDE_CONFIG: 'TOGGLE_SHOW_SLIDE_CONFIG',
  TOGGLE_SHOW_MODAL_LAYOUT_CONFIG: 'TOGGLE_SHOW_MODAL_LAYOUT_CONFIG',
  TOGGLE_SHOW_MODAL_EXCLUIR: 'TOGGLE_SHOW_MODAL_EXCLUIR',
  TOGGLE_SHOW_BLANK_BOARD: 'TOGGLE_SHOW_BLANK_BOARD',
  TOGGLE_SHOW_MODAL_TALKING: 'TOGGLE_SHOW_MODAL_TALKING',
  COPY_BLOCKS: 'COPY_BLOCKS',
  PASTE_BLOCKS: 'PASTE_BLOCKS',
  UNDO: 'UNDO',
  SET_QUIZ_TEXTO_QUESTAO: 'SET_QUIZ_TEXTO_QUESTAO',
  EXCLUIR_QUIZ_OPCAO: 'EXCLUIR_QUIZ_OPCAO',
  SET_OPCAO_CORRETA: 'SET_OPCAO_CORRETA',
  SET_QUIZ: 'SET_QUIZ',
  EXCLUIR_QUIZ_QUESTAO: 'EXCLUIR_QUIZ_QUESTAO',
  SET_QUIZ_INDEX: 'SET_QUIZ_INDEX',
  SET_SHOW_CARD_TOAST: 'SET_SHOW_CARD_TOAST',
  SET_QUIZ_DEFAULT: 'SET_QUIZ_DEFAULT',
  SET_IMAGE_QUESTAO: 'SET_IMAGE_QUESTAO',
  SET_IS_DRAGGING: 'SET_IS_DRAGGING',
  SET_IN_INPUT: 'SET_IN_INPUT',
  SET_LIVRO_CORES: 'SET_LIVRO_CORES',
  DELETE_MATCH_OPTION: 'DELETE_MATCH_OPTION',
  SET_CARACTERE_VERDADEIRO: 'SET_CARACTERE_VERDADEIRO',
  SET_CARACTERE_FALSO: 'SET_CARACTERE_FALSO',
  SET_QUIZ_QUESTAO_CONFIGS: 'SET_QUIZ_QUESTAO_CONFIGS',
  TOGGLE_SHOW_TEXT_CONFIG: 'TOGGLE_SHOW_TEXT_CONFIG',
  TOGGLE_SHOW_INPUT_CONFIG: 'TOGGLE_SHOW_INPUT_CONFIG',
  TOGGLE_SHOW_TABLE_CONFIG: 'TOGGLE_SHOW_TABLE_CONFIG',
  TOGGLE_SHOW_DICE_CONFIG: 'TOGGLE_SHOW_DICE_CONFIG',
  TOGGLE_SHOW_GAME_CARD_CONFIG: 'TOGGLE_SHOW_GAME_CARD_CONFIG',
  TOGGLE_SHOW_TOTEM_CONFIG: 'TOGGLE_SHOW_TOTEM_CONFIG',
  TOGGLE_SHOW_CRONOMETRO_CONFIG: 'TOGGLE_SHOW_CRONOMETRO_CONFIG',
  TOGGLE_SHOW_RANDOMCARD_CONFIG: 'TOGGLE_SHOW_RANDOMCARD_CONFIG',
  TOGGLE_SHOW_TOTEM: 'TOGGLE_SHOW_TOTEM',
  SET_AUDIO_QUESTAO: 'SET_AUDIO_QUESTAO',
  SET_VIDEO_QUESTAO: 'SET_VIDEO_QUESTAO',
  TOGGLE_SHOW_MODAL_CLONAR_AULA: 'TOGGLE_SHOW_MODAL_CLONAR_AULA',
  SET_AULA: 'SET_AULA',
  TOGGLE_SHOW_GALERIA_IMAGENS: 'TOGGLE_SHOW_GALERIA_IMAGENS',
  SET_BLOCK: 'SET_BLOCK',
  REDO: 'REDO',
  TOGGLE_SHOW_GALERIA_AVATARES: 'TOGGLE_SHOW_GALERIA_AVATARES',
  SET_AVATAR: 'SET_AVATAR',
  SET_AVATAR_LETTER: 'SET_AVATAR_LETTER',
  SET_FALAS_TALKING: 'SET_FALAS_TALKING',
  SET_OPCOES_FALA_TALKING: 'SET_OPCOES_FALA_TALKING',
  SET_PRACTICE: 'SET_PRACTICE',
  SET_PRACTICE_DEFAULT: 'SET_PRACTICE_DEFAULT',
  SET_AVATAR_URL: 'SET_AVATAR_URL',
  TOGGLE_SHOW_EXTRA_WORD: 'TOGGLE_SHOW_EXTRA_WORD',
  TOGGLE_SHOW_LOADING: 'TOGGLE_SHOW_LOADING',
  SET_DEFAULT_BACKGROUND: 'SET_DEFAULT_BACKGROUND',
  ADD_BLOCK_SELECTED: 'ADD_BLOCK_SELECTED',
  SET_CONTROL_IS_PRESSED: 'SET_CONTROL_IS_PRESSED',
  RESET_BLOCKS_SELECTED: 'RESET_BLOCKS_SELECTED',
  SET_PLAYERS_PLACAR: 'SET_PLAYERS_PLACAR',
  SET_PLAYER_SELECTED_PLACAR: 'SET_PLAYER_SELECTED_PLACAR',
  NEXT_PLAYER_PLACAR: 'NEXT_PLAYER_PLACAR',
  SET_END_GAME_PLACAR: 'SET_END_GAME_PLACAR',
  TOGGLE_SHOW_DROPABLE_CONFIG: 'TOGGLE_SHOW_DROPABLE_CONFIG',
  ADD_TOTEM: 'ADD_TOTEM',
  SET_TOTEM: 'SET_TOTEM',
  TOGGLE_SHOW_MODAL_UNITS: 'TOGGLE_SHOW_MODAL_UNITS',
  TOGGLE_SHOW_MODAL_LIVROS: 'TOGGLE_SHOW_MODAL_LIVROS',
  SET_SLIDES_COUNT: 'SET_SLIDES_COUNT',
  SET_CART: 'SET_CART',
  SET_CREDIT_CARD: 'SET_CREDIT_CARD',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  SET_RECEIVING_METHOD: 'SET_RECEIVING_METHOD',
  SET_TOTAL: 'SET_TOTAL',
  SET_BOLETO: 'SET_BOLETO',
  SET_PLAYER_SCORE: 'SET_PLAYER_SCORE',
  INCREASE_CONTENT_SCALE: 'INCREASE_CONTENT_SCALE',
  DECREASE_CONTENT_SCALE: 'DECREASE_CONTENT_SCALE',
  RESET_CONTENT_SCALE: 'RESET_CONTENT_SCALE',
  RESET_PLAYER_SCORE: 'RESET_PLAYER_SCORE',
  SET_DESCONTAR_PONTOS: 'SET_DESCONTAR_PONTOS',
  SET_QUIZ_RESPONDIDO: 'SET_QUIZ_RESPONDIDO',
  SET_PLAY_BLOCK_KEY: 'SET_PLAY_BLOCK_KEY',
  SET_BLOCKS_AUTO_EXECUTE: 'SET_BLOCKS_AUTO_EXECUTE',
  NEXT_EXECUTE: 'NEXT_EXECUTE',
  SET_FAST_CARD_KEY: 'SET_FAST_CARD_KEY',
  SET_LAST_CARD_SELECTED: 'SET_LAST_CARD_SELECTED',
  RESET_EXECUCAO_STATE: 'RESET_EXECUCAO_STATE',
  SET_STATE_PLAY_AUDIOS: 'SET_STATE_PLAY_AUDIOS',
  SET_INDICE_AUDIO: 'SET_INDICE_AUDIO',
  SET_PLAYER_SECONDS: 'SET_PLAYER_SECONDS',
  SET_PLAYING_SECONDS: 'SET_PLAYING_SECONDS',
  SET_HIDE_HIDABLES: 'SET_HIDE_HIDABLES',
  SET_CARD_CLICKED: 'SET_CARD_CLICKED',
  SET_CARD_ALEATORIO: 'SET_CARD_ALEATORIO',
  SET_TOTEM_POSITION: 'SET_TOTEM_POSITION',
  SET_QUESTAO_QUIZ: 'SET_QUESTAO_QUIZ',
  SET_NONE_BLOCK: 'SET_NONE_BLOCK',
  PREVIOUS_PLAYER_PLACAR: 'PREVIOUS_PLAYER_PLACAR',
  SET_LAST_AUDIO_PLAYED: 'SET_LAST_AUDIO_PLAYED',
  SET_SIZE_CONTENT: 'SET_SIZE_CONTENT',
  SET_MATCH_STATE: 'SET_MATCH_STATE',
  SET_STATE: 'SET_STATE',
  SET_CLICK_INDEX: 'SET_CLICK_INDEX',
  ADD_SHOW_BLOCKS_INDEX: 'SET_SHOW_BLOCKS_INDEX',

  SET_TIC_TAC_TOE_BOARD: 'SET_TIC_TAC_TOE_BOARD',
  SET_TIC_TAC_TOE_X_IS_NEXT: 'SET_TIC_TAC_TOE_X_IS_NEXT',
  SET_TIC_TAC_TOE_WINNER: 'SET_TIC_TAC_TOE_WINNER',
  TOGGLE_SHOW_HANGMAN_CONFIG: 'TOGGLE_SHOW_HANGMAN_CONFIG',
  TOGGLE_SHOW_FOOD_GAME_CONFIG: 'TOGGLE_SHOW_FOOD_GAME_CONFIG',
  SET_FOODS_SELECTED: 'SET_FOODS_SELECTED',
  SET_RANDOM_FOODS: 'SET_RANDOM_FOODS',
  SET_CORRECT_FOODS: 'SET_CORRECT_FOODS',
  RESET_FOOD_GAME: 'RESET_FOOD_GAME',
  SET_FOOD_GAME_RESET_FUNCTION: 'SET_FOOD_GAME_RESET_FUNCTION',
  TOGGLE_SHOW_BASKETBALL_CONFIG: 'TOGGLE_SHOW_BASKETBALL_CONFIG',
  TOGGLE_SHOW_GLOBE_GAME_CONFIG: 'TOGGLE_SHOW_GLOBE_GAME_CONFIG',
  SET_SLIDE_ID_PARAM: 'SET_SLIDE_ID_PARAM',
  TOGGLE_SHOW_DARK_ROOM_CONFIG: 'TOGGLE_SHOW_DARK_ROOM_CONFIG',
  TOGGLE_SHOW_MEMORY_GAME_CONFIG: 'TOGGLE_SHOW_MEMORY_GAME_CONFIG',
  TOGGLE_SHOW_PARKING_GAME_CONFIG: 'TOGGLE_SHOW_PARKING_GAME_CONFIG',
  SET_PARKING_GAME_RESET_FUNCTION: 'SET_PARKING_GAME_RESET_FUNCTION',
  SET_QUIZ_SUBMETER_CORRECAO: 'SET_QUIZ_SUBMETER_CORRECAO',
  SET_QUIZ_QUESTAO_VALOR: 'SET_QUIZ_QUESTAO_VALOR',
  TOGGLE_SHOW_BAG_GAME_CONFIG: 'TOGGLE_SHOW_BAG_GAME_CONFIG',
  TOGGLE_SHOW_SHEEP_GAME_CONFIG: 'TOGGLE_SHOW_SHEEP_GAME_CONFIG',
  TOGGLE_SHOW_ZOO_GAME_CONFIG: 'TOGGLE_SHOW_ZOO_GAME_CONFIG',
  TOGGLE_SHOW_PREPOSITION_GAME_CONFIG: 'TOGGLE_SHOW_PREPOSITION_GAME_CONFIG',
  TOGGLE_SHOW_MONSTER_GAME_CONFIG: 'TOGGLE_SHOW_MONSTER_GAME_CONFIG',
  TOGGLE_SHOW_FIREFIGHTER_GAME_CONFIG: 'TOGGLE_SHOW_FIREFIGHTER_GAME_CONFIG',
  TOGGLE_SHOW_DOMINO_GAME_CONFIG: 'TOGGLE_SHOW_DOMINO_GAME_CONFIG',
  SET_MEMORY_GAME_OPTIONS_CARD: 'SET_MEMORY_GAME_OPTIONS_CARD',
  SET_MEMORY_GAME_CARDS_SELECTED: 'SET_MEMORY_GAME_CARDS_SELECTED',
  SET_RESET_MEMORY_GAME_FUNCTION: 'SET_RESET_MEMORY_GAME_FUNCTION',
  SET_RESET_SNOOKER_GAME_FUNCTION: 'SET_RESET_SNOOKER_GAME_FUNCTION',
  SET_RESET_GLOBE_GAME_FUNCTION: 'SET_RESET_GLOBE_GAME_FUNCTION',
  SET_RESET_PREPOSITION_GAME_FUNCTION: 'SET_RESET_PREPOSITION_GAME_FUNCTION',
  TOGGLE_SHOW_SEQUENCE_IMAGES_GAME_CONFIG:
    'TOGGLE_SHOW_SEQUENCE_IMAGES_GAME_CONFIG',
  SET_SEQUENCE_IMAGES_GAME_STATE: 'SET_SEQUENCE_IMAGES_GAME_STATE',
  TOGGLE_SHOW_WORKPLACE_GAME_CONFIG: 'TOGGLE_SHOW_WORKPLACE_GAME_CONFIG',
  SET_WORKPLACE_GAME_STATE: 'SET_WORKPLACE_GAME_STATE',
  SET_ENUNCIADO_QUESTAO: 'SET_ENUNCIADO_QUESTAO',
  SET_SHEEP_GAME_RESET_FUNCTION: 'SET_SHEEP_GAME_RESET_FUNCTION',
  RESET_TOTEMS: 'RESET_TOTEMS',
  SET_COR_TEXTO_PRIMARIA: 'SET_COR_TEXTO_PRIMARIA',
  SET_CARD_INDEX: 'SET_CARD_INDEX',
  FLIP_CARD: 'FLIP_CARD',
  SET_CARD_RESET: 'SET_CARD_RESET',
  RESET_CARD_REDUCER: 'RESET_CARD_REDUCER',
  SET_IS_LIVE: 'SET_IS_LIVE',
  SET_SELECTOR: 'SET_SELECTOR',
  SET_FINISH_LESSON: 'SET_FINISH_LESSON',
  SET_BALLS_COPY: 'SET_BALLS_COPY',
  SET_SELECTED_BALL: 'SET_SELECTED_BALL',
  SET_IMG_SINUCA: 'SET_IMG_SINUCA',
  SET_ROOM: 'SET_ROOM',
  SET_CARD_CHALLENGE: 'SET_CARD_CHALLENGE',
  RESET_CARDS_CHALLENGE: 'RESET_CARDS_CHALLENGE',
  ADD_PARTICIPANT: 'ADD_PARTICIPANT',
  SET_SOCKET: 'SET_SOCKET',
  SET_CONNECTED_USERS: 'SET_CONNECTED_USERS',
  SET_TALKING_INDEX: 'SET_TALKING_INDEX',
  SET_IS_MUTE_ALL: 'SET_IS_MUTE_ALL',
  SET_IS_PREVIOUS_AUDIO_STATUS: 'SET_IS_PREVIOUS_AUDIO_STATUS',
  SET_IS_AUDIO_PLAYING: 'SET_IS_AUDIO_PLAYING'
};

export default actionsTypes;

import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  showModalBackground: false,
  showModalImage: false,
  showModalVideo: false,
  showModalAudio: false,
  showModalTable: false,
  showModalQuiz: false,
  showModalCronometro: false,
  showQuiz: false,
  showTalking: false,
  showConfig: false,
  fullScreen: true,
  showLayoutSelector: false,
  showGameToolsSelector: false,
  showTextTools: false,
  showModalControls: false,
  showToast: false,
  showModalAddCurso: false,
  showModalAddDivisao: false,
  showModalAddLivro: false,
  showModalAddItem: false,
  showJanelaDireita: false,
  titleJanelaDireita: 'Talking',
  showModalClickable: false,
  showImageConfig: false,
  showAudioConfig: false,
  showVideoConfig: false,
  showClickableConfig: false,
  showSlideConfig: false,
  showModalLayoutConfig: false,
  showModalExcluir: false,
  showBlankBoard: false,
  showCardToast: {
    show: false,
    text: '',
    type: 'danger',
    voice: false,
    points: 0
  },
  showTextConfig: false,
  showInputConfig: false,
  showTableConfig: false,
  showModalClonarAula: false,
  showGaleriaImagens: false,
  showGaleriaAvatares: false,
  showDiceConfig: false,
  showExtraWord: false,
  showLoading: false,
  showTotemConfig: false,
  showCronometroConfig: false,
  showGameCardConfig: false,
  showTotem: false,
  showDropableConfig: false,
  showRandomCardConfig: false,
  showModalUnits: false,
  showModalLivros: false,
  hideHidables: false,
  finishLesson: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.TOGGLE_SHOW_MODAL_BACKGROUND:
      return {
        ...state,
        showModalBackground: !state.showModalBackground
      };
    case actionsTypes.TOGGLE_SHOW_MODAL_IMAGE:
      return { ...state, showModalImage: !state.showModalImage };

    case actionsTypes.TOGGLE_SHOW_MODAL_VIDEO:
      return { ...state, showModalVideo: !state.showModalVideo };

    case actionsTypes.TOGGLE_SHOW_MODAL_AUDIO:
      return { ...state, showModalAudio: !state.showModalAudio };

    case actionsTypes.TOGGLE_SHOW_MODAL_TABLE:
      return { ...state, showModalTable: !state.showModalTable };

    case actionsTypes.TOGGLE_SHOW_MODAL_CRONOMETRO:
      return { ...state, showModalCronometro: !state.showModalCronometro };

    case actionsTypes.TOGGLE_FULL_SCREEN:
      return { ...state, fullScreen: !state.fullScreen, showBlankBoard: false };

    case actionsTypes.TOGGLE_SHOW_JANELA_DIREITA:
      return {
        ...state,
        showJanelaDireita: !state.showJanelaDireita,
        showQuiz: false,
        showTalking: false,
        showConfig: false,
        showImageConfig: false,
        showAudioConfig: false,
        showClickableConfig: false,
        showSlideConfig: false,
        showTextConfig: false,
        showInputConfig: false,
        showTableConfig: false,
        showVideoConfig: false,
        titleJanelaDireita: '',
        showDiceConfig: false,
        showTotemConfig: false,
        showCronometroConfig: false,
        showGameCardConfig: false,
        showTotem: false,
        showDropableConfig: false,
        showRandomCardConfig: false
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_QUIZ:
      return {
        ...state,
        showJanelaDireita: !state.showJanelaDireita,
        showQuiz: !state.showQuiz,
        titleJanelaDireita: 'Quiz'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_TALKING:
      return {
        ...state,
        showJanelaDireita: !state.showJanelaDireita,
        showTalking: !state.showTalking,
        titleJanelaDireita: 'Talking'
      };

    case actionsTypes.TOGGLE_SHOW_LAYOUT_SELECTOR:
      return { ...state, showLayoutSelector: !state.showLayoutSelector };

    case actionsTypes.TOGGLE_SHOW_GAMETOOLS_SELECTOR:
      return { ...state, showGameToolsSelector: !state.showGameToolsSelector };

    case actionsTypes.SET_SHOW_TEXT_TOOLS:
      return { ...state, showTextTools: action.show };

    case actionsTypes.TOGGLE_SHOW_MODAL_CONTROLS:
      return { ...state, showModalControls: !state.showModalControls };

    case actionsTypes.TOGGLE_SHOW_TOAST:
      return { ...state, showToast: !state.showToast };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_CURSO:
      return { ...state, showModalAddCurso: !state.showModalAddCurso };
    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_DIVISAO:
      return { ...state, showModalAddDivisao: !state.showModalAddDivisao };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_LIVRO:
      return { ...state, showModalAddLivro: !state.showModalAddLivro };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_ITEM:
      return { ...state, showModalAddItem: !state.showModalAddItem };

    case actionsTypes.TOGGLE_SHOW_MODAL_CLICKABLE:
      return { ...state, showModalClickable: !state.showModalClickable };

    case actionsTypes.TOGGLE_SHOW_IMAGE_CONFIG:
      return {
        ...state,
        showImageConfig: !state.showImageConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Imagem'
      };

    case actionsTypes.TOGGLE_SHOW_AUDIO_CONFIG:
      return {
        ...state,
        showAudioConfig: !state.showAudioConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Áudio'
      };

    case actionsTypes.TOGGLE_SHOW_VIDEO_CONFIG:
      return {
        ...state,
        showVideoConfig: !state.showVideoConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Vídeo'
      };

    case actionsTypes.TOGGLE_SHOW_CLICKABLE_CONFIG:
      return {
        ...state,
        showClickableConfig: !state.showClickableConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Clickable'
      };

    case actionsTypes.TOGGLE_SHOW_INPUT_CONFIG:
      return {
        ...state,
        showInputConfig: !state.showInputConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Campo de texto'
      };

    case actionsTypes.TOGGLE_SHOW_TEXT_CONFIG:
      return {
        ...state,
        showTextConfig: !state.showTextConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Texto'
      };

    case actionsTypes.TOGGLE_SHOW_TABLE_CONFIG:
      return {
        ...state,
        showTableConfig: !state.showTableConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Tabela'
      };

    case actionsTypes.TOGGLE_SHOW_SLIDE_CONFIG:
      return {
        ...state,
        showSlideConfig: !state.showSlideConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Configurações da aula e slide'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_LAYOUT_CONFIG:
      return { ...state, showModalLayoutConfig: !state.showModalLayoutConfig };

    case actionsTypes.TOGGLE_SHOW_MODAL_EXCLUIR:
      return { ...state, showModalExcluir: !state.showModalExcluir };

    case actionsTypes.TOGGLE_SHOW_BLANK_BOARD:
      return { ...state, showBlankBoard: !state.showBlankBoard };

    case actionsTypes.SET_SHOW_CARD_TOAST:
      return {
        ...state,
        showCardToast: {
          show: action.show,
          text: action.text,
          type: action.tipo,
          voice: action.voice,
          points: action.points
        }
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_CLONAR_AULA:
      return {
        ...state,
        showModalClonarAula: !state.showModalClonarAula
      };

    case actionsTypes.TOGGLE_SHOW_GALERIA_IMAGENS:
      return { ...state, showGaleriaImagens: !state.showGaleriaImagens };

    case actionsTypes.TOGGLE_SHOW_GALERIA_AVATARES:
      return { ...state, showGaleriaAvatares: !state.showGaleriaAvatares };

    case actionsTypes.TOGGLE_SHOW_DICE_CONFIG:
      return {
        ...state,
        showDiceConfig: !state.showDiceConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Dado'
      };
    case actionsTypes.TOGGLE_SHOW_GAME_CARD_CONFIG:
      return {
        ...state,
        showGameCardConfig: !state.showGameCardConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Card'
      };
    case actionsTypes.TOGGLE_SHOW_TOTEM_CONFIG:
      return {
        ...state,
        showTotemConfig: !state.showTotemConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Totem'
      };

    case actionsTypes.TOGGLE_SHOW_EXTRA_WORD:
      return { ...state, showExtraWord: !state.showExtraWord };

    case actionsTypes.TOGGLE_SHOW_CRONOMETRO_CONFIG:
      return {
        ...state,
        showCronometroConfig: !state.showCronometroConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Cronômetro'
      };
    case actionsTypes.TOGGLE_SHOW_RANDOMCARD_CONFIG:
      return {
        ...state,
        showRandomCardConfig: !state.showRandomCardConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Random Card'
      };
    case actionsTypes.TOGGLE_SHOW_TOTEM:
      return {
        ...state,
        showTotem: !state.showTotem,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Totem'
      };
    case actionsTypes.TOGGLE_SHOW_LOADING:
      return { ...state, showLoading: !state.showLoading };

    case actionsTypes.TOGGLE_SHOW_DROPABLE_CONFIG:
      return {
        ...state,
        showDropableConfig: !state.showDropableConfig,
        showJanelaDireita: !state.showJanelaDireita,
        titleJanelaDireita: 'Dropable'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_UNITS:
      return {
        ...state,
        showModalUnits: !state.showModalUnits
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_LIVROS:
      return {
        ...state,
        showModalLivros: !state.showModalLivros
      };

    case actionsTypes.SET_HIDE_HIDABLES:
      return {
        ...state,
        hideHidables: action.hideHidables
      };

    case actionsTypes.SET_STATE:
      if (action.state?.ShowReducer) return action.state.ShowReducer;
      return state;

    case actionsTypes.SET_FINISH_LESSON:
      return {
        ...state,
        finishLesson: action.finishLesson
      };

    default:
      return state;
  }
}
